<template>
  <TopNav></TopNav>
  <h1>KDV Hesaplama</h1>
  <fieldset>
    <legend>KDV Hesaplama Aracı</legend>
    <input type="radio" name="f" value="c" id="c" v-model="secili"><label for="c">KDV dahil tutardan KDV hariç fiyat hesaplama</label><br>
    <input type="radio" name="f" value="d" id="d" v-model="secili"><label for="d">KDV hariç fiyattan KDV dahil tutar hesaplama</label><br>
    <input type="radio" name="f" value="e" id="e" v-model="secili"><label for="e">KDV'den matrah bulma</label><br>
    <br>
    KDV Oranı: <input type="radio" name="o" value=1 id="f" v-model.number="oran"><label for="f">%1</label>&nbsp;
    <input type="radio" name="o" value=8 id="g" v-model.number="oran"><label for="g">%8</label>&nbsp;
    <input type="radio" name="o" value=18 id="h" v-model.number="oran"><label for="h">%18</label>&nbsp;
    <label for="i">Diğer %</label><input type="number" name="o" id="i" v-model.number="oran" step="any">
    <br><br>
    <label for="b">{{ yazi() }}:</label> <input type="number" v-model.number="inputB" id="b" step="any"><br>
    <br>
    KDV Hariç Tutar: <span class="blue">{{ kdvharictutar() }} ₺</span><br>
    KDV: <span class="blue">{{ kdvtutar() }} ₺</span><br>
    KDV Dahil Tutar: <span class="blue">{{ kdvdahiltutar() }} ₺</span>
  </fieldset>
</template>

<script>
import TopNav from './components/TopNav.vue'

export default {
  name: "Kdv",
  data() {
    return {
      inputB: 118,
      secili: "c",
      oran: 18
    }
  },
  components: {
    TopNav
  },
  methods: {
    yazi: function () {
      if (this.secili == "c") {
        return "KDV Dahil Tutar";
      } else if (this.secili == "d") {
        return "KDV Hariç Tutar";
      } else {
        return "KDV Tutarı";
      }
    },
    kdvharictutar: function () {
      if (this.secili == "c") {
        var tplmkdv = 100 + this.oran;
        var brmkdv = this.inputB / tplmkdv;
        var kdvtutar = this.oran * brmkdv;
        return (this.inputB - kdvtutar).toFixed(2);
      }
      if (this.secili == "d") {
        return this.inputB;
      }
      if (this.secili == "e") {
        var yuzdebiroran = this.inputB / this.oran;
        return (yuzdebiroran * 100).toFixed(2);
      }
    },
    kdvtutar: function () {
      if (this.secili == "c") {
        var tplmkdv = 100 + this.oran;
        var brmkdv = this.inputB / tplmkdv;
        return (this.oran * brmkdv).toFixed(2);
      }
      if (this.secili == "d") {
        return ((this.inputB / 100) * this.oran).toFixed(2);
      }
      if (this.secili == "e") {
        return (this.inputB).toFixed(2);
      }
    },
    kdvdahiltutar: function () {
      if (this.secili == "c") {
        return this.inputB;
      }
      if (this.secili == "d") {
        return ((this.inputB / 100) * this.oran) + this.inputB;
      }
      if (this.secili == "e") {
        var yuzdebiroran = this.inputB / this.oran;
        var yuzdeyuz = yuzdebiroran * 100;
        return (yuzdeyuz + this.inputB).toFixed(2);
      }
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  margin: 1em auto;
  margin-top: 0;
  max-width: 40em;
  font-family: Arial, Helvetica, sans-serif;
}

footer section a {
  margin: 20px;
}

.blue {
    color: blue;
}
</style>
